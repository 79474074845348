import React, { useEffect } from 'react'

import { tsParticles, ILoadParams } from '@tsparticles/engine'

import { loadConfettiPreset } from '@tsparticles/preset-confetti'

import {
  actions,
  useStore,
} from '@assets/store'

//to use it call useConfetti with options for tsParticles confetti

/* 
second parameter is to enable conditionally the animation (false at first and has to be switched to true to start animation)

example 

const [enable, setEnable] = useState(false)

setTimeout(()=>{setEnable(true)}, delay)

will enable the animation after delay
*/

// options https://particles.js.org/docs/interfaces/tsParticles_Emitters_Plugin.Options_Interfaces_IEmitter.IEmitter.html#fill

export async function useConfetti(options: ILoadParams, condition: boolean = true) {

  const isloaded = useStore().app.loadConfettiPreset()

  useEffect(() => {
    (
      async () => {
        if (!isloaded) {
          await loadConfettiPreset(tsParticles)
          actions.app.loadConfettiPreset(true)
        }

        if (condition) {
          let p = await tsParticles.load(options)
        }
      }
    )()
  }, [isloaded, condition])

  return isloaded
}