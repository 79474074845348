import { FC, useEffect, useMemo, useCallback } from "react"

import { Modal, Button } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import { format } from 'date-fns'
import { parseDate } from '@assets/utils'

import './style.sass'

import { formatPriceToBr } from "@/utils"

import { brazilian_text_to_searchable } from "@assets/functions"
import { formatPrice } from '@assets/utils/formatPrice'

import { ReactComponent as ArrowDownSVG } from './ArrowDown.svg'
import { ReactComponent as CancelSVG } from './Cancel.svg'
import { ReactComponent as DoneSVG } from './Done.svg'
import { ReactComponent as ExclamationSVG } from './Exclamation.svg'
import { ReactComponent as WatchSVG } from './Watch.svg'

import { CreditOperation } from '@assets/types'

//to test transactions uncomment followin and comment transactions in component
//import { transactions } from './test_transactions'

const check_creditOperation_status = (creditOperation: CreditOperation, status: string) => {
  return [brazilian_text_to_searchable(creditOperation.commissionStatus ?? ''), brazilian_text_to_searchable(creditOperation.creditStatus ?? '')].some(s => s == status)
}

/* 
To show cashback operations
*/

export const CashbackModal: FC = () => {

  const dispatch = useAppDispatch()

  const {
    showCashbackModal,
    transactions,
    advertiser
  } = useAppSelector(
    (state) => state.propzMedia
  )

  useEffect(() => {
    console.log('transactions : ', transactions)
  }, [])

  const statements = useMemo(() => {
    let final: (any | never)[] = [];
    try {
      (transactions || []).forEach((transaction: any) => {
        if (transaction.creditRedeems?.length > 1) {
          transaction.creditRedeems?.forEach((credit: any) => {
            if (!(Number(credit.amount) < 0.01)) {
              let cr = JSON.parse(JSON.stringify(credit))
              if (credit.operation?.ticketJson) {
                cr.operation.ticket = JSON.parse(credit.operation.ticketJson)
              }
              final.push({ ...transaction, ...{ creditRedeems: [cr] } })
            }
          })
        } else {
          final.push(transaction)
        }
      })
      return final
    }
    catch (e) {
      console.log('transactions statements transactions : ', transactions)
      console.log('transactions statements error : ', e)
    }
  }, [transactions])

  const transactionStatus = useCallback((transaction: any) => {

    if (transaction.creditRedeems && transaction.creditRedeems[0] && transaction.creditRedeems[0]?.debitType == 'USAGE' && transaction.creditRedeems[0]?.operation?.type == "DEBIT") {
      return (
        <>
          <ArrowDownSVG /> Utilizado
        </>
      )
    }

    if (transaction.creditRedeems && transaction.creditRedeems[0] && (transaction.creditRedeems[0]?.debitType == 'EXPIRATION' || transaction.creditRedeems[0]?.debitType == "EXPIRED")) {
      return (
        <>
          <WatchSVG /> Expirado
        </>
      )
    }

    if (check_creditOperation_status(transaction.creditOperation, 'approved')) {
      return (
        <>
          <DoneSVG /> Recebido
        </>
      )
    }

    if (check_creditOperation_status(transaction.creditOperation, 'pending')) {
      return (
        <>
          <ExclamationSVG /> A receber
        </>
      )
    }

    return (
      <>
        <CancelSVG /> Cancelado
      </>
    )
  }, [])

  const line3 = useCallback((transaction: any) => {

    if (transaction.creditRedeems && transaction.creditRedeems[0] && transaction.creditRedeems[0]?.debitType == 'USAGE' && transaction.creditRedeems[0]?.operation?.type == "DEBIT") {
      return (
        <>
          {'Data da utilização: '}
          {transaction.creditRedeems[0]?.dateCreated ? format(parseDate(transaction.creditRedeems[0]?.dateCreated) as Date, 'dd/MM/yyyy') : ''}
        </>
      )
    }

    if (transaction.creditRedeems && transaction.creditRedeems[0] && (transaction.creditRedeems[0]?.debitType == 'EXPIRATION' || transaction.creditRedeems[0]?.debitType == "EXPIRED")) {
      return (
        <>
          {'Data da expiração: '}
          {transaction.creditRedeems[0]?.dateCreated ? format(parseDate(transaction.creditRedeems[0]?.dateCreated) as Date, 'dd/MM/yyyy') : ''}
        </>
      )
    }

    if (check_creditOperation_status(transaction.creditOperation, 'approved')) {
      return (
        <>
          {'Data de aprovação: '}
          {transaction.creditDateCreated ? format(parseDate(transaction.creditDateCreated) as Date, 'dd/MM/yyyy') : ''}
        </>
      )
    }

    if (check_creditOperation_status(transaction.creditOperation, 'pending')) {
      return (
        <>
          {'Data provável de aprovação: '}
          {transaction.approvalPrevisionDate ? format(parseDate(transaction.approvalPrevisionDate) as Date, 'dd/MM/yyyy') : ''}
        </>
      )
    }

    return (
      <>
        {'Data do cancelamento: '}
        {transaction.dateUpdated ? format(parseDate(transaction.dateUpdated) as Date, 'dd/MM/yyyy') : ''}
      </>
    )
  }, [])

  const store_name = useCallback((id: any) => {
    let adv = advertiser.find(store => store.advertiserId == id)?.name
    return adv ? ` na ${adv}` : ''
  }, [])

  return (
    <Modal
      actions={[]}
      bottomSheet
      id="cashbackModal"
      open={showCashbackModal}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "4%",
        onCloseEnd: () => dispatch(setPropzMediaStateParameter({ showCashbackModal: false })),
      }}
    >

      <h4
        className="header"
      >
        Meu extrato
        <Button
          flat
          waves="cyan"
          className="white stores-close-button"

          onClick={() => dispatch(setPropzMediaStateParameter({ showCashbackModal: false }))}
        >
          <i className="material-icons">close</i>
        </Button>
      </h4>

      <h5 className="saldo_atual">
        {'Saldo atual: '}
        <span>
          {
            formatPriceToBr(window.personalBalance)
          }
        </span>
      </h5>

      <ul
        className="collection"
      >

        {statements?.map((transaction: any, i) => (

          <li key={`transaction-${i}`} className="collection-item">

            <div className="line">
              {'Cashback: '}
              <span className="amount">
                {/*<span className="money_sign">R$ </span>*/}
                {
                  formatPrice(transaction.creditRedeems?.[0]?.amount || transaction?.amount || 0)
                }
              </span>

              {transactionStatus(transaction)}

            </div>

            <div className="line">
              {'Compra'}
              {store_name(transaction.creditOperation.advertiserId)}
              {' de '}
              {
                formatPriceToBr(transaction?.creditRedeems?.[0]?.operation?.ticket?.ticket?.amountWithAllDiscount || transaction?.creditOperation?.saleAmount?.amount || 0)
              }
              {' realizada em '}
              {
                transaction?.creditOperation?.dateCreated ? format(parseDate(transaction?.creditOperation?.dateCreated) as Date, 'dd/MM/yyyy') : ''
              }
            </div>

            <div className="line">
              {line3(transaction)}
            </div>

          </li>

        ))}

      </ul>
    </Modal>
  );
};