import { FC, useMemo, } from "react"

import { useAppSelector } from "@/app/hooks"

/* 
  Counter of new reminders that user didn't see
*/

export const NewRemindersCount: FC = () => {

  const {
    reminders,
    lastViewedReminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const newReminders: number = useMemo(() => {

    return reminders?.filter(r => !lastViewedReminders?.includes(`${r.productId}:${r.remainingDays}`)).length || 0

  }, [reminders, lastViewedReminders])

  return (
    <div className={`CallToAction number ${newReminders > 0 ? 'visible' : ''}`}>
      {newReminders}
    </div>
  )
};
