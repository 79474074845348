import React, { FC, useMemo, } from "react"

import { pdfStore } from '@assets/store/pdfStore'
import { useTestidPrefix } from '@assets/hooks/useTestidPrefix'

interface Props {
  wifi?: boolean;
  event?: (e: React.MouseEvent) => void;
}

// Terms and documents component for some pages with form

export const TermsDocuments: FC<Props> = ({ wifi, event }) => {
  const prefix = useTestidPrefix()

  const docs = useMemo(() => [
    ...wifi && window.parameters.glob_wifiTerms ? [<a
      onClick={e => {

        if (event) {
          event(e)
        }

        pdfStore.set.PDFPopupState({
          url: window.parameters.glob_wifiTerms,
          opened: true,
          title: `Termos de uso do WI-FI de relacionamento ${window.parameters.glob_programName}`,
        })
      }}
      data-testid={`${prefix}_terms_wifi`}
    >
      termos de uso do WI-FI
    </a>] : [],


    ...window.parameters.glob_programGeneralRules ? [<a
      onClick={e => {

        if (event) {
          event(e)
        }

        pdfStore.set.PDFPopupState({
          url: window.parameters.glob_programGeneralRules,
          opened: true,
          title: `Termos de uso do programa de relacionamento ${window.parameters.glob_programName}`,
        })
      }}
      data-testid={`${prefix}_terms_general_rules`}
    >
      termos de uso
    </a>] : [],

    ...window.parameters.glob_programRules ? [<a
      onClick={(e) => {

        if (event) {
          event(e)
        }

        pdfStore.set.PDFPopupState({
          url: window.parameters.glob_programRules,
          opened: true,
          title: `Regulamento do programa de relacionamento ${window.parameters.glob_programName}`,
        })
      }}
      data-testid={`${prefix}_terms_rules`}
    >
      regulamento do programa
    </a>] : [],

    ...window.parameters.glob_marketplaceRules ? [<a
      onClick={e => {

        if (event) {
          event(e)
        }

        pdfStore.set.PDFPopupState({
          url: window.parameters.glob_marketplaceRules,
          opened: true,
          title: `Regulamento do marketplace do programa de relacionamento ${window.parameters.glob_programName}`,
        })
      }}
      data-testid={`${prefix}_terms_marketplace_rules`}
    >
      regulamento do marketplace
    </a>] : [],

    ...window.parameters.glob_programPrivacyPolicy ? [<a
      onClick={e => {

        if (event) {
          event(e)
        }

        pdfStore.set.PDFPopupState({
          url: window.parameters.glob_programPrivacyPolicy,
          opened: true,
          title: `Política de privacidade do programa de relacionamento ${window.parameters.glob_programName}`,
        })
      }}
      data-testid={`${prefix}_terms_privacy_policy`}
    >
      política de privacidade
    </a>] : [],

  ], [])

  return (
    <span data-testid={`${prefix}_terms_checkbox_text`}>
      {
        window.parameters.glob_LGPDFlow ?
          'Estou ciente dos '
          :
          'Ao continuar, confirmo estar ciente dos '
      }

      {
        docs.length > 1 ?
          <>
            {
              docs.length > 2 ?
                docs.slice(0, docs.length - 2).map(e => <> {e}{', '} </>)
                : <></>
            }
            {
              docs[docs.length - 2]
            }
            {' e '}
            {docs[docs.length - 1]}
          </>
          : docs.map(e => e)
      }
    </span>
  )
}