import { FC, useCallback } from "react"

import { useAppDispatch, } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import {
  Icon,
  QueryLink,
} from '@/parts'

import "./style.sass"

import {
  questionSVG,
  dialogSVG,
  protectedSVG,
  gooddocSVG,
  approvedSVG,
  propz_clubSVG,
  mappointSVG
} from '@/assets/icons'

import {
  actions,
} from '@assets/store'
import { pdcStore } from "@assets/store/pdcStore"

export const Ajuda: FC = () => {

  const dispatch = useAppDispatch()

  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const tileRef = useCallback(node => {
    if (node) {
      node.style.animationDelay = `.${[...node.parentNode?.children].indexOf(node)}s`
    }
  }, [])

  return (
    <div className="ajuda_page">

      <div className="header scale_in_animation">
        Olá! Como podemos te ajudar hoje?
      </div>

      <div className="tiles">

        {
          marketplaceEnable ?
            <QueryLink
              to="/shopping/faq"
              className="tile scale_in_animation"
              ref={tileRef}
            >
              <Icon image={questionSVG} className='tile_icon' />
              <span>FAQ</span>
            </QueryLink>
            : ''
        }

        <div
          className="tile scale_in_animation"
          onClick={() => {
            dispatch(setPropzMediaStateParameter({ showFaleConoscoModal: true }))
          }}
          ref={tileRef}
        >
          <Icon image={dialogSVG} className='tile_icon' />
          <span>Fale Conosco</span>
        </div>

        {
          window.parameters.glob_programPrivacyPolicy ?
            <a
              className="tile scale_in_animation"

              href={window.parameters.glob_programPrivacyPolicy}
              target="_blank"
              rel="noreferrer"
              ref={tileRef}
            >
              <Icon image={protectedSVG} className='tile_icon' />
              <span>Politica de privacidade</span>
            </a>
            : ''
        }

        {
          window.parameters.glob_programGeneralRules ?
            <a
              className="tile scale_in_animation"

              href={window.parameters.glob_programGeneralRules}
              target="_blank"
              rel="noreferrer"
              ref={tileRef}
            >
              <Icon image={gooddocSVG} className='tile_icon' />
              <span>Termos de uso do programa</span>
            </a>
            : ''
        }

        {
          window.parameters.glob_programRules ?
            <a
              className="tile scale_in_animation"

              href={window.parameters.glob_programRules}
              target="_blank"
              rel="noreferrer"
              ref={tileRef}
            >
              <Icon image={approvedSVG} className='tile_icon' />
              <span>Regulamento do programa</span>
            </a>
            : ''
        }

        {
          marketplaceEnable && window.parameters.glob_marketplaceRules ?
            <a
              className="tile scale_in_animation"

              href={window.parameters.glob_marketplaceRules}
              target="_blank"
              rel="noreferrer"
              ref={tileRef}
            >
              <Icon image={propz_clubSVG} className='tile_icon' />
              <span>Regulamento do marketplace</span>
            </a>
            : ''
        }

        <div
          className="tile scale_in_animation"
          onClick={() => {
            actions.app.showStoresSearchPopup(true)
          }}
          ref={tileRef}
        >
          <Icon image={mappointSVG} className='tile_icon' />
          <span>Encontre nossas lojas</span>
        </div>

      </div>

    </div >
  );
};
