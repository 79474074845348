import { FC, useMemo, } from "react"

import { Modal, Button } from "react-materialize"

import "./style.sass"

import { ReactComponent as CartSVG } from './cart_error.svg'
import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg'
import { ReactComponent as CloudSVG } from '@/assets/icons/cloud.svg'

import { vipcommerce_redirect } from "@assets/utils/vipcommerce_redirect"

import { useComponentSize } from "@assets/hooks/useComponentSize"
import { pdcStore } from "@assets/store/pdcStore"
import { useDebounceCallback } from "usehooks-ts"
import { ecommerceCartProduct } from "@assets/types"

/* 
  Popup for information on error when sending add to cart request
*/

export const EcommerceCartErrorPopup: FC = () => {

  const showEcommerceCartErrorPopup = pdcStore.use.showEcommerceCartErrorPopup()

  const ecommerceFailures = pdcStore.use.ecommerceFailures()

  const cart = pdcStore.use.ecommerceCart()
  const mainPromotions = pdcStore.use.mainPromotions()

  const [content_ref, popup_size] = useComponentSize(
    showEcommerceCartErrorPopup,
  )

  const ecommerceFailuresList = useMemo(() => ecommerceFailures.map((failure_id) => cart.find(product => product.productId == failure_id)).filter(p => p && p?.quantity) as ecommerceCartProduct[]
    , [
      ecommerceFailures,
      mainPromotions,
      cart,
    ])

  const on_close = useDebounceCallback(() => {
    pdcStore.set.showEcommerceCartErrorPopup(false)

    if (pdcStore.get.redirectAfterEcommerceCartErrorPopup()) {
      vipcommerce_redirect()
    }
  }, 50)

  return (
    <Modal
      id="EcommerceCartErrorPopup"
      open={
        showEcommerceCartErrorPopup
      }
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: on_close,
      }}
      //@ts-ignore
      className={
        `${ecommerceFailures?.length ? 'has_failures' : ''} ${showEcommerceCartErrorPopup ? 'open' : ''}`
      }
      style={{
        ...ecommerceFailures?.length ? {
          '--failures': ecommerceFailures.length > 2 ? 3 : ecommerceFailures.length,
        } : {},
      }}
    >
      <div className="popup_content" ref={content_ref} style={{
        ...popup_size?.width ? { "--popup-width": `${popup_size?.width}px`, } as React.CSSProperties : {},
      }}>
        <div className="top_gap"></div>

        <Button
          flat
          className="white close-button"

          onClick={on_close}
        >
          <CloseSVG />
        </Button>

        <div className="image">
          <CloudSVG className="cloud" />
          <CartSVG className="cart" />
        </div>

        <div className="header">
          Ops! Alguns produtos podem não estar disponíveis
        </div>

        <div className="subheader">
          {
            ecommerceFailures?.length ?
              <span>Os produtos abaixo <span style={{ fontWeight: 600 }}>podem</span> não ter sido adicionados por não estarem disponíveis no momento. Verifique seu carrinho antes de finalizar a compra.</span>
              :
              'Alguns dos produtos selecionados não foram adicionados por não estarem disponíveis no momento. Verifique seu carrinho antes de finalizar a compra.'
          }
        </div>

        {
          ecommerceFailuresList.length ?
            <div className="failures_list">
              {
                ecommerceFailuresList.map(product => (
                  <div className="failures_list_product">
                    <span className="failures_list_product_name">
                      {product.name ?? ''}
                    </span>
                    <span className="failures_list_product_quantity">
                      {product.quantity} {` unidade`}{product.quantity > 1 ? 's' : ''}
                    </span>
                  </div>
                ))
              }
            </div>
            : <></>
        }

        <div className="bottom_gap"></div>
      </div>
    </Modal>
  );
};