import { FC, useEffect, useMemo, useCallback } from "react"

import { useLocation } from "react-router-dom"

import { Modal, Button } from "react-materialize"

import { useAppSelector } from "@/app/hooks"

import { useMediaQuery } from 'react-responsive'

import "./style.sass"
import { QueryLink } from "@/parts"

import { formatDuration } from 'date-fns'
import ptBR from "date-fns/locale/pt-BR"

import { pdcStore } from "@assets/store/pdcStore"

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

export const RemindersPopup: FC = () => {

  const { pathname } = useLocation()

  const {
    reminders,
    RemindersLinkPosition,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const RemindersPopupOpened = pdcStore.use.RemindersPopupOpened()

  const lastClosedPopup = pdcStore.use.lastClosedPopup()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    /* 
      Placed near the link to Reminders page
    */
    if (RemindersLinkPosition) {

      let m = document.querySelector<HTMLElement>('#RemindersPopup')

      const width = Math.min(374, window.innerWidth - 16)

      if (isMobile) {
        let p = (window.innerWidth - width) / 2

        m?.style.setProperty('right', `${p}px`)

        m?.style.setProperty('left', `${p}px`)

        m?.style.setProperty('--arrow-left', `${RemindersLinkPosition.x - p}px`)

      } else {

        m?.style.setProperty('--top', `${RemindersLinkPosition.y}px`, 'important')

        if (window.innerWidth < 850) {
          m?.style.setProperty('--arrow-left', `${RemindersLinkPosition.x - 80}px`)

          m?.style.setProperty('left', `100px`, 'important')
          m?.style.setProperty('right', 'auto')

        } else {

          m?.style.setProperty('--arrow-left', '35px')

          m?.style.setProperty('left', `${RemindersLinkPosition.x - 10}px`, 'important')
          m?.style.setProperty('right', 'auto')
        }
      }

    }
  }, [RemindersLinkPosition])

  const closePopup = useCallback((wait?: boolean) => {
    pdcStore.set.RemindersPopupOpened(false)

    setTimeout(() => {
      if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
        //if can show one more -> switch last closed popup
        pdcStore.set.lastClosedPopup('RemindersPopup')
      }
    }, wait ? orderedPopupTimings.RemindersPopup : 0)

  }, [])

  useEffect(() => {
    if (lastClosedPopup == 'ContinueRegistrationModal' && reminders) {

      if (reminders.length) {

        pdcStore.set.RemindersPopupOpened(true)
        pdcStore.set.increment_showed_popups_count()

      } else {
        closePopup()
      }
    }
  }, [reminders, lastClosedPopup])

  const singleReminder = useMemo(() => (reminders?.length ?? 0) > 1, [reminders])

  return (
    <Modal
      actions={[
        <QueryLink
          to='/lembretes'
          className="text_button"
        >
          <Button flat modal="close" node="button" className="primary_background text_primary_background common_button_font_size common_button_border_radius common_button_shadow common_text_button common_button_font_size_mobile">
            {
              window.parameters.reminders_supermarket ?
                singleReminder ?
                  'Encontre aqui seu produto'
                  :
                  'Encontre aqui seus produtos'
                :
                singleReminder ?
                  'Encontre aqui seu medicamento'
                  :
                  'Encontre aqui seus medicamentos'
            }
          </Button>
        </QueryLink>
      ]}
      id="RemindersPopup"
      //@ts-ignore
      className={`${window.parameters.reminders_supermarket ?
        singleReminder ?
          'nonpharma_single'
          :
          'nonpharma_multiple'
        :
        singleReminder ?
          'pharma_single'
          : 'pharma_multiple'}`}
      open={
        RemindersPopupOpened
        && !!RemindersLinkPosition
        && !pathname.includes('cadastro')
        && !pathname.includes('webpush-pagina')
        && !pathname.includes('lembretes')
      }
      header={
        window.parameters.reminders_supermarket ?
          singleReminder ?
            'Seu produto favorito deve estar no final...'
            :
            'Seus produtos favoritos devem estar no final...'
          :
          singleReminder ?
            'Seu medicamento está no final...'
            :
            'Seus medicamentos estão no final...'
      }
      options={{
        dismissible: true,
        onCloseEnd: () => closePopup(true)
      }}
    >
      <div className="reminders">
        {reminders?.slice(0, 3).map(reminder =>
          <div className="reminder">
            {
              window.parameters.reminders_supermarket ?
                <>
                  {'Seu produto '} <span className="name">{reminder?.data?.name}</span>
                  {' deve estar acabando.'}
                </>
                :
                <>
                  {'Seu medicamento '} <span className="name">{reminder?.data?.name}</span>
                  {
                    reminder.remainingDays > 0
                      ?
                      ` acaba em ${formatDuration({ days: reminder.remainingDays }, { locale: ptBR })}.`
                      :
                      ' acabou?'
                  }
                </>
            }

          </div>
        )}
      </div>
    </Modal>
  );
};