import { FC, useEffect, useState, } from "react"

import {
  Modal,
  TextInput,
  Button,
  Checkbox,
} from "react-materialize"

import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  setPropzMediaStateParameter,
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import InputMask from "react-input-mask"

import { useMediaQuery } from 'react-responsive'

import {
  useTrackedStore,
} from '@assets/store'

import {
  preparePhone,
} from '@assets/utils'

import '@/assets/sass/components/ContactSkippedPopup.sass'

import '&/sass/components/input-field.sass'

/* 
  Popup for registration flow if user didn't provide email or phone
*/

export const PhoneSkippedPopup: FC = () => {

  const dispatch = useAppDispatch()

  const {
    showPhoneSkippedPopup,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const customer = useTrackedStore().app.customer()

  let validationSchema = yup.object({
    mobilePhone: yup
      .string()
      .required('Insira um número de celular válido')
      .test({
        message: 'Insira um número de celular válido',
        test: value => {
          if (value && value?.length) {
            let phone = preparePhone(value)
            if (phone?.length != 11 || ["00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"].includes(phone)) return false;
            return true
          } else return false
        }
      }),
  })

  const formik = useFormik({
    initialValues: {
      mobilePhone: preparePhone(customer?.mobilePhone?.replace('+55', '')),
      smsContact: customer?.smsContactFlag && !customer?.pmUnsubscribedSms,
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {

      document.body.style.cursor = 'wait';

      let phone = '+55' + preparePhone(value.mobilePhone)

      let props = {
        mobilePhone: phone,
        smsContactFlag: value.smsContact,
        pmUnsubscribedSms: !value.smsContact
      }

      dispatch(updateCustomer(props))
        .finally(() => {
          document.body.style.cursor = 'default'
          formik.setSubmitting(false);
          dispatch(setPropzMediaStateParameter({ showPhoneSkippedPopup: false }))
        });
    },
  })

  const isNotMobile = useMediaQuery({ query: '(min-width: 768.1px)' }) ? 'input-outlined' : ''

  const [inputNode, setInputNode] = useState<HTMLInputElement | null>()

  useEffect(() => {
    if (inputNode) {
      //@ts-ignore
      inputNode.addEventListener('input', function (e: InputEvent) {

        if (!e.inputType?.includes('delete') && (e.data?.length ?? 2 > 1)) {

          let input = e.target as HTMLInputElement

          let v = preparePhone(input.value)

          formik
            .setFieldValue("mobilePhone", v)
            //this will update cursor position to the end of input (fix for cursor not changing position)
            .finally(() => {
              setTimeout(() => {
                let l = inputNode.value.length
                inputNode.setSelectionRange(l, l)
              }, 20)
            })
        }
      })
    }
  }, [inputNode])

  return (
    <Modal
      //@ts-ignore
      className="ContactSkippedPopup"
      open={
        showPhoneSkippedPopup
        && !customer.mobilePhone
        && !customer.homeEmail
      }
      options={{
        dismissible: false,
        preventScrolling: true,
        onCloseEnd: () => dispatch(setPropzMediaStateParameter({ showPhoneSkippedPopup: false })),
      }}
    >

      <div
        className="header"
      >
        Cadastre um dado de contato
      </div>

      <div className="subheader">
        É importante informar ao menos um meio de contato para garantir o acesso à sua conta.
      </div>

      <form onSubmit={formik.handleSubmit} >
        {/* email */}
        <InputMask mask="(99) 99999-9999"
          value={formik.values.mobilePhone}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          maskChar={null}
        >
          {(inputProps: any) =>
            <TextInput
              id="mobilePhone"
              name="mobilePhone"
              label="Celular"
              type="tel"
              inputMode="numeric"

              autofocus="true"

              //@ts-ignore
              ref={(e) => {
                setInputNode(e)
              }}

              className={`${isNotMobile} ${formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone) && 'invalid' || ''}`}
              {...inputProps}

              error={formik.touched.mobilePhone && formik.errors.mobilePhone || ' '}

              disabled={formik.isSubmitting}
            />
          }
        </InputMask>

        <div className="checkbox_container">
          <Checkbox
            className="rounded_checkbox"
            checked={!!formik?.values.smsContact}
            filledIn
            name="smsContact"
            id="smsContact"
            //@ts-ignore
            value={false}
            onChange={formik?.handleChange}
            disabled={formik?.isSubmitting}
          />

          <span>
            Quero receber ofertas no número informado.
          </span>
        </div>

        {/* Submit */}
        <Button
          flat
          //@ts-ignore
          type="submit"
          disabled={
            formik.isSubmitting
            || !formik.isValid
          }
          className="text_primary_background common_text_button primary_background common_button_font_size common_button_border_radius common_button_shadow"
        >
          Continuar
        </Button>

        <a
          className="alt_skip_link"
          onClick={() => {
            dispatch(setPropzMediaStateParameter({ showPhoneSkippedPopup: false }))
            dispatch(setPropzMediaStateParameter({ showEmailSkippedPopup: true }))
          }}
        >
          Informar e-mail
        </a>

      </form>

      <div className="footer">
        Caso não queira receber nossas ofertas no número informado, sua escolha será respeitada.
      </div>

    </Modal>
  );
};