import {
  Offer,
  Store,
  Customer,
  DataLoading,
} from '@assets/types'

import { createStore } from 'zustand-x'

type AppStore = {
  customerId: string;
  anonEmail: string;
  anonPhone: string;
  enteredEmail: string;
  signature: string;
  customerExists: boolean;
  OfferConditionsModalOpened: boolean;
  OfferConditionsModalItem: Offer | undefined;
  pageEl: HTMLDivElement | null;
  stores: Store[];
  CurrentOfferStoresModalPromotion: Offer | undefined;
  currentOfferStoresModalOpened: boolean;
  openRegistrationModal: boolean;
  registrationRedirectLink: string;
  customer: Customer;
  showStoresSearchPopup: boolean;
  //if loadConfettiPreset is loaded
  loadConfettiPreset: boolean;
  //has to be set at app start
  query: URLSearchParams | null;
  dataLoading: DataLoading;
  enableVisitor: boolean;
  disableSign: boolean;
}

const initialState = {
  customerId: '',
  anonEmail: '',
  anonPhone: '',
  signature: '',
  enteredEmail: '',
  customerExists: false,
  OfferConditionsModalOpened: false,
  OfferConditionsModalItem: undefined,
  pageEl: null,
  stores: [],
  CurrentOfferStoresModalPromotion: undefined,
  currentOfferStoresModalOpened: false,
  openRegistrationModal: false,
  registrationRedirectLink: '/cadastro-nome',
  customer: {},
  showStoresSearchPopup: false,
  StoresSearchPopupStores: [],
  loadConfettiPreset: false,
  query: null,
  dataLoading: {
    advertising: true,
    transactions: true,
    reminders: false,
    customer: true,
  },
  enableVisitor: false,
  disableSign: false,
} as AppStore

export const appStore = createStore('app')(initialState)
  .extendActions((set, get, api) => ({
    reset: () => {
      Object.getOwnPropertyNames(set)
        .filter(n => ![
          'mergeState',
          'state'
        ].includes(n))
        //@ts-ignore
        .forEach(n => set[n](initialState[n]))
    },
    update_dataLoading: (data: Partial<DataLoading>) => {
      set.dataLoading({ ...get.dataLoading(), ...data })
    }
  }))