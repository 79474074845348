import { FC, } from "react"

import { useLocation } from "react-router-dom"

import { Button, Modal } from "react-materialize"

import { useMediaQuery } from 'react-responsive'

import { ReactComponent as PhoneSVG } from './phone.svg'

import "./style.sass"

// Popup to ask user to use portrait mode on mobiles

export const PhoneFlip: FC = () => {

  const { pathname } = useLocation()

  const portrait = useMediaQuery({ query: '(orientation: portrait)' })

  const mobile = 'ontouchstart' in window

  const narrow = useMediaQuery({ query: '(max-height: 480px)' })

  return (
    <Modal
      //@ts-ignore
      className="phone_flip_modal"
      header='Para uma melhor experiência, recomendamos o uso do seu dispositivo no sentido vertical.'
      open={mobile && !portrait && narrow && !pathname.includes('cadastro') && !pathname.includes('shopping')}

      id="phone_flip_modal"
      options={{
        dismissible: false,
        endingTop: "5%",
        startingTop: "5%",
        //onCloseEnd: () => setOpen(false)
      }}
    >
      <PhoneSVG />
      <Button flat node="button" waves="light" modal="close" className="primary_background text_primary_background"
      >
        Entendi
      </Button>
    </Modal>
  );
};
