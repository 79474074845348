import React, { FC, useEffect, useMemo } from "react";

import hands_with_phone from './hands_with_phone.png'

import { useApp } from '@assets/hooks/useApp'

import './style.sass'
/* 
Banner to show in top carousel for PWA/app installation
*/
export const AppCTABanner: FC = () => {

  const {
    glob_programLogo,
  } = window.parameters

  const [_, action] = useApp('banner')

  return (
    <div
      className={`banner app_cta_banner text_primary_background`}
      onClick={action}
    >

      <div className="image" style={{
        backgroundImage: `url(${hands_with_phone})`
      }} >
        <div className="logo" style={{
          backgroundImage: `url(${glob_programLogo})`
        }} />
      </div>

      <div className="text_content">
        <div className="header">
          É mais fácil no APP
        </div>

        <div className="subheader">
          Instale o App e receba novas ofertas na palma da sua mão!
        </div>

        <div className="install_button">
          Instalar
        </div>
      </div>

    </div >
  );
};
