export const parametersObjectToQueryString = (parameters: { [x: string]: any }) => {
  return Object.getOwnPropertyNames(parameters)
    .filter(key => !!parameters[key])
    .map(key => `${key}=${parameters[key]}`)
    .join('&')
}
/* 
{
  promotion: promotionId,
  someOtherProperty: someOtherPropertyValue
} => 'promotion=promotionId&someOtherProperty=someOtherPropertyValue'
*/