import { FC, useMemo, useEffect, } from "react"
import { useLocation } from "react-router-dom"

import './style.sass'

import { pdcStore } from "@assets/store/pdcStore"

import { useApp } from '@assets/hooks/useApp'

import { ReactComponent as CloseSVG } from '@assets/icons/close.svg'

/* 
Popup to install PWA/app
*/

export const TopBarAppCTA: FC = () => {

  const { pathname } = useLocation()

  const TopBarAppCTAOpened = pdcStore.use.TopBarAppCTAOpened()

  const close = () => {
    pdcStore.set.TopBarAppCTAOpened(false)
  }

  const [show_popup, action] = useApp('headerbar', close)

  const opened = useMemo(() =>
    !pathname.includes('cadastro')
    && !pathname.includes('webpush-pagina')
    && TopBarAppCTAOpened
    && show_popup
    , [
      pathname,
      TopBarAppCTAOpened,
      show_popup,
    ])

  useEffect(() => {
    if (opened) {
      window.dataLayer.push({
        event: "pwa_install_headerbar_view",
      })
    }
  }, [opened])

  return (
    <div id="TopBarAppCTA" onClick={action} className={`${opened ? 'opened' : ''}`}>

      <CloseSVG className="close_icon" onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        close()
      }} />

      <div className="logo_image" >
        <img
          src={window.parameters.glob_programLogo}
          alt={window.parameters.glob_programName} />
      </div>

      <div className="texts">
        <div className="header">
          Mais ofertas no App
        </div>

        <div className="subheader">
          É grátis e ocupa pouco espaço. Instale agora!
        </div>
      </div>

      <div className="install_button">
        Instalar
      </div>

    </div>
  )
}