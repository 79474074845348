import React, { FC, useEffect, useCallback, } from "react"
import { useLocation } from "react-router-dom"

import { Modal, Button } from "react-materialize"

import "./style.sass"

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import celularPNG from "./celular.png"
import backshadowSVG from '@/assets/icons/webpush_backshadow.svg'

import { ReactComponent as CloseSVG } from '@assets/icons/close.svg'

import { pdcStore } from "@assets/store/pdcStore"

import { useComponentSize } from '@assets/hooks/useComponentSize'
import { useDebounceCallback } from "usehooks-ts"

import { useApp } from '@assets/hooks/useApp'

import {
  notificationsNotAvailable,
  showed_later_days_diff,
} from '@/utils/internal functions'
import { localStore } from "@/store/localStore"

/* 
  Big popup for PWA/app installation
*/

export const SplashscreenAppCTA: FC = () => {

  const { pathname } = useLocation()

  const lastClosedPopup = pdcStore.use.lastClosedPopup()

  const showSplashscreenAppCTA = pdcStore.use.showSplashscreenAppCTA()

  const closePopup = useCallback(
    useDebounceCallback(
      (wait?: boolean) => {
        pdcStore.set.showSplashscreenAppCTA(false)

        setTimeout(() => {
          if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
            //if can show one more -> switch last closed popup
            pdcStore.set.lastClosedPopup('SplashscreenAppCTA')
          }
        }, wait ? orderedPopupTimings.SplashscreenAppCTA : 0)
      }, 100)
    , [])


  const [show_popup, action] = useApp('splashscreen', closePopup)

  useEffect(() => {
    if (lastClosedPopup == 'RecentlyActivatedPromotionsModal') {

      const lastShowedSplashscreenAppCTA = showed_later_days_diff(localStore.get.lastShowedSplashscreenAppCTA(), window.parameters.splashscreen_app_cta_period)

      if (
        lastShowedSplashscreenAppCTA
        && show_popup
      ) {
        pdcStore.set.showSplashscreenAppCTA(true)

        pdcStore.set.TopBarAppCTAOpened(false)

        pdcStore.set.increment_showed_popups_count()

        window.dataLayer.push({
          event: 'pwa_install_splashscreen_view'
        })

        localStore.set.lastShowedSplashscreenAppCTA(new Date().getTime())
      }
      else {
        if (!notificationsNotAvailable()) {

          const lastShowedDeniedNotificationsModal = showed_later_days_diff(localStore.get.lastShowedDeniedNotificationsModal(), window.parameters.denied_push_popup_period)

          if (lastShowedDeniedNotificationsModal) {

            pdcStore.set.checkNotificationsPermission('push_notification_ordered_popup_flow')

            return
          }
        } else {
          return closePopup()
        }
        pdcStore.set.showFullsizeWebpushPopup(true)
      }
    }
  }, [
    lastClosedPopup,
    show_popup,
  ])

  const [content_ref, popup_size] = useComponentSize(
    showSplashscreenAppCTA
    && !pathname.includes('/cadastro')
    && !pathname.includes('webpush-pagina'),
  )

  return (
    <Modal
      id="splashscreen_cta_popup"

      open={
        showSplashscreenAppCTA
        && !pathname.includes('/cadastro')
        && !pathname.includes('webpush-pagina')
      }
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: () => closePopup(true),
      }}
      //@ts-ignore
      className="primary_background text_primary_background"
    >
      <div className="content" ref={content_ref} style={{
        ...popup_size?.height ? { "--popup-height": `${popup_size?.height}px`, } as React.CSSProperties : {},
      }} onClick={action}>

        <CloseSVG className="close_icon" onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          closePopup(true)
        }} />

        <div className="image" style={{ backgroundImage: `url(${celularPNG}), url(${backshadowSVG})` }} />

        <div className="text">

          <div className="header">
            Receba ofertas exclusivas na palma da sua mão!
          </div>

          <div className="subheader">
            Baixe nosso APP e não perca estas vantagens!
          </div>
        </div>

        {/* @ts-ignore */}
        <Button flat waves="light" className="big_button common_text_button common_button_border_radius common_button_shadow primary_color_text"
          onClick={action}
        >
          Eu quero!
        </Button>

        <Button flat node="button" waves="light" className="deny_button text_primary_background"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            closePopup(true)
          }}
        >
          Não quero baixar o APP :(
        </Button>

      </div>
    </Modal>
  );
};