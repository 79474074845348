import { useLocation, } from "react-router-dom"

// returns testid prefix for templates

export function useTestidPrefix() {

  const { pathname } = useLocation()

  const page = ((pathname ?? '').replaceAll('/', '') ? (pathname ?? '').replaceAll('/', '') : 'root')

  return `${window.currentTemplate}_${page}`.replaceAll('-', '_')
}