import { FC, useEffect, useState, useCallback } from "react"
import { useLocation } from "react-router-dom"

import { Modal, Button } from "react-materialize"

import { useAppDispatch, } from "@/app/hooks"

import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import "./style.sass"

import {
  notificationsNotAvailable,
  showed_later_days_diff,
  denyNotifications,
} from '@/utils/internal functions'

import {
  useTrackedStore,
} from '@assets/store'

import bellSVG from '@/assets/icons/bell.svg'
import backshadowSVG from '@/assets/icons/webpush_backshadow.svg'

import { pdcStore } from "@assets/store/pdcStore"
import { useDebounceCallback } from "usehooks-ts"
import { localStore } from "@/store/localStore"

export const FullsizeWebpushPopup: FC = () => {

  const dispatch = useAppDispatch()

  const { pathname } = useLocation()

  const showFullsizeWebpushPopup = pdcStore.use.showFullsizeWebpushPopup()

  const customer = useTrackedStore().app.customer()

  //used to disable popup flickering
  const [popupOpen, setPopupOpen] = useState(false)

  const closePopup = useCallback(
    useDebounceCallback((wait?: boolean) => {
      pdcStore.set.showFullsizeWebpushPopup(false)

      setTimeout(() => {
        if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
          pdcStore.set.lastClosedPopup('SplashscreenAppCTA')
        }
      }, wait ? orderedPopupTimings.SplashscreenAppCTA : 0)
    }, 100)
    , [])

  useEffect(() => {
    //set in SplashscreenAppCTA to true
    if (showFullsizeWebpushPopup) {

      const lastShowedFullsizeWebpushPopup = showed_later_days_diff(localStore.get.lastShowedFullsizeWebpushPopup(), window.parameters.fullsize_webpush_period)

      if (
        lastShowedFullsizeWebpushPopup
        && !Number(window.rawUser.webPushContactFlag)
        && !notificationsNotAvailable()
      ) {

        setPopupOpen(true)
        pdcStore.set.increment_showed_popups_count()

        window.dataLayer.push({
          event: 'push_notification_splashscreen_view',
        })

        localStore.set.lastShowedFullsizeWebpushPopup(new Date().getTime())
      }
      else {
        closePopup()
      }
    }
  }, [showFullsizeWebpushPopup, closePopup])

  const action = useCallback(() => {

    dispatch(updateCustomer({ webPushContactFlag: true }))

    pdcStore.set.checkNotificationsPermission('push_notification_splashscreen_click')

    closePopup(true)

  }, [customer])

  return (
    <Modal
      id="fullsize-webpush-popup"
      open={
        showFullsizeWebpushPopup
        && popupOpen
        && !pathname.includes('/cadastro')
        && !pathname.includes('webpush-pagina')
      }
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: () => closePopup(true)
      }}
      //@ts-ignore
      className="primary_background text_primary_background"
    >
      <div className="image" style={{ backgroundImage: `url(${bellSVG}), url(${backshadowSVG})` }} />

      <div className="text">

        <div className="header">
          Quer receber ofertas exclusivas para você?
        </div>

        <div className="subheader">
          Ative as notificações e não perca estas vantagens!
        </div>
      </div>

      <div className="buttons">

        <Button flat node="button" waves="light" className="big_button common_text_button common_button_border_radius common_button_shadow primary_color_text"
          onClick={() => action()}
        >
          Ative agora mesmo!
        </Button>

        <Button flat node="button" waves="light" className="deny_button text_primary_background"
          onClick={() => {
            closePopup(true)
            denyNotifications()
          }}
        >
          Não quero receber ofertas exclusivas :(
        </Button>

      </div>

    </Modal>
  );
};