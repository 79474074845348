import { FC, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom"

import { styled } from '@linaria/react'

import {
  NewRemindersCount,
  MarketplaceCallToAction,
} from '@/components'

import {
  LinkCountedPosition,
  QueryLink,
  Icon,
} from '@/parts'

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import { logout } from "@/utils/internal functions/logout"

import {
  useTrackedStore,
} from '@assets/store'

/* SVG */
import { ReactComponent as ListSVG } from '@/assets/icons/list.svg'
import { ReactComponent as LogoutSVG } from '@/assets/icons/logout.svg'
import { ReactComponent as PersonSVG } from '@/assets/icons/person.svg'
import { ReactComponent as PlusSVG } from './Plus.svg'
import { ReactComponent as ExpandMoreSVG } from '@/assets/icons/ExpandMore.svg'

import { ajudaSVG } from '@/assets/icons'
import IzioSVG from '@assets/icons/izio.svg'
import CupomSVG from '@assets/icons/cupom.svg'

/* Styles */
import "./style.sass"
import { pdcStore } from "@assets/store/pdcStore"

const Logo_link = styled.a`
  display: flex;
  align-items: center;
  margin: 0 10px;
  height: 100%;

  img {
    max-width: 135px;
    width: auto;
    height: auto;
    max-height: 100%;
  }
`

/* 
  Top navigation bar
*/

export const Header: FC = () => {

  let { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const {
    glob_programName,
    glob_programLogo,
  } = window.parameters

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const customer = useTrackedStore().app.customer()
  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  /* initialize dropdown "Greetings" */
  const GreetingsDropdownRef = useCallback(node => {
    if (node != null) {
      node.addEventListener('mouseover', () => {
        window.M.Dropdown.init(node, {
          coverTrigger: false,
          constrainWidth: false,
        })
      }, { once: true })
    }
  }, [])

  useEffect(() => { }, [])

  const HeaderRef = useCallback(node => {
    if (node !== null) {
      dispatch(setPropzMediaStateParameter({ headerHeight: node.offsetHeight }))
    }
  }, [])

  const { registrationDisable } = useMemo(() => window.parameters, [])

  return (
    <nav className="header" ref={HeaderRef}>

      <div className="nav-wrapper">

        {
          !window.inIframe ?
            <Logo_link className="propz_logo">
              <img src={glob_programLogo}
                alt={glob_programName} />
            </Logo_link>
            : ''
        }

        {
          pathname != '/unsubscription'
            ?
            <>
              <ul id="nav-mobile" className={`left ${window.inIframe ? 'inIframe' : 'not_in_iframe'}`}>

                {
                  (window.inIframe ? marketplaceEnable : true)
                    ?
                    <li>
                      <QueryLink
                        to="/"
                        className={`menu-link ${pathname == '/' && "menu-link-active" || ''}`}
                      >
                        Ofertas
                      </QueryLink>
                    </li>
                    : ''
                }

                {marketplaceEnable ?
                  <li>
                    {
                      window.inIframe ?
                        <QueryLink
                          to="/shopping"
                          className={`menu-link ${pathname == '/shopping' && "menu-link-active" || ''}`}
                        >
                          Cashback
                        </QueryLink>
                        :
                        <LinkCountedPosition
                          link="/shopping" name="Cashback"
                          setPosition={'CashbackLinkPosition'}
                          callToAction={<MarketplaceCallToAction />}
                          place='header'
                        />
                    }
                  </li>
                  : ''
                }

                {
                  !window.inIframe && reminders?.length ?
                    <li>
                      <LinkCountedPosition
                        link="/lembretes" name="Lembretes"
                        setPosition={'RemindersLinkPosition'}
                        callToAction={<NewRemindersCount />}
                        place='header'
                      />
                    </li>
                    : ''
                }

              </ul>

              {
                window.parameters.cashbackDisabled
                  || pathname.includes('/cadastro-')
                  ?
                  ''
                  :
                  <a
                    className={`cashback_amount_link right menu-link ${!window.inIframe ? 'isNotInIframe' : ''}`}
                    onClick={() => dispatch(setPropzMediaStateParameter({ showCashbackModal: true }))}
                  >
                    {'Saldo cashback: '}
                    <span className="light">
                      {
                        Number(window.personalBalance || 0)
                          .toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          })
                      }
                    </span>
                    <PlusSVG />
                  </a>
              }


              {
                !window.inIframe ?
                  <a
                    className="name_dropdown_link right menu-link menu-link-active dropdown-trigger"
                    data-target="greetings_dropdown" ref={GreetingsDropdownRef}
                  >
                    Olá
                    <strong>
                      {
                        customer?.firstName ?
                          `, ${customer.firstName.split(' ')[0]}` : ""}
                    </strong>!
                    <ExpandMoreSVG className="right" />
                  </a>
                  : ''
              }
            </>
            : ''
        }

      </div>

      {/* Greetings dropdown content */}
      <ul id="greetings_dropdown" className="dropdown-content">

        {marketplaceEnable && (
          <li>
            <QueryLink to="/shopping">
              <ListSVG />
              <span>Meu extrato</span>
            </QueryLink>
          </li>
        )}

        {
          registrationDisable != 'true'
            ?
            (registrationDisable == '' || registrationDisable == 'false')
              ?
              <li>
                <QueryLink to="/cadastro">
                  <PersonSVG />
                  <span>Informações pessoais</span>
                </QueryLink>
              </li>
              :
              <a href={registrationDisable} target="_blank">
                <PersonSVG />
                <span>Informações pessoais</span>
              </a>
            : ''
        }

        {
          window.settings['template.accessible.integration.benefits.izio.enable'] ?
            <li>
              <QueryLink to="/benefits/izio">
                <Icon image={IzioSVG} className="menu_link_icon" />
                <span>Vantagens</span>
              </QueryLink>
            </li>
            : <></>
        }

        {
          window.settings['template.accessible.integration.benefits.cv.enable'] ?
            <li>
              <QueryLink to="/benefits/cv">
                <Icon image={CupomSVG} className="menu_link_icon" />
                <span>Cupom Verde</span>
              </QueryLink>
            </li>
            : <></>
        }

        <li>
          <QueryLink to="/ajuda">
            <Icon image={ajudaSVG} className="menu_link_icon" />
            <span>Ajuda</span>
          </QueryLink>
        </li>

        <li>
          <a
            className="logout"
            onClick={logout}
          >
            <LogoutSVG />
            <span className="">Sair da conta</span>
          </a>
        </li>

      </ul>

    </nav>
  );
};
