import { FC, } from "react"
import { Collapsible, CollapsibleItem, } from "react-materialize"

import './style.sass'

import { TopBanner } from "@/components"

/* SVG */
import { ReactComponent as CartIcon } from './assets/cart.svg'
import { ReactComponent as FingerIcon } from './assets/finger.svg'
import { ReactComponent as PiggyIcon } from './assets/piggy.svg'
import { Offer } from "@assets/types"

export const FAQ: FC = () => {

  const { glob_programName } = window.parameters

  function closeCollapsibles(n: number) {
    let collapsibles = document.querySelectorAll('ul.collapsible')
    collapsibles.forEach((r) => {

      if (Number((r as HTMLElement)?.dataset?.id) != n) {
        M.Collapsible.getInstance(r).close(0)
      }
    })
  }

  const topBannerItem = {
    promotion: {
      mechanic: 'shopping_page',
      discountPercent: 10
    }
  } as unknown as Offer

  const middleCards = [
    {
      icon: <FingerIcon />,
      text: <div className="text">Selecione uma <span className="primary_text">loja parceira</span> no portal {glob_programName} e clique no botão "Eu quero!" para abrir o link de compras.</div>
    },
    {
      icon: <CartIcon />,
      text: <div className="text">Escolha os produtos e faça sua compra na loja selecionada através do link que foi aberto no seu navegador.</div>
    },
    {
      icon: <PiggyIcon />,
      text: <div className="text">Receba dinheiro de volta na sua conta Propz Club. Informe o CPF no momento da compra para utilizar seu saldo.</div>
    },
  ]

  const quiz = [
    {
      question: 'Realizei uma compra, mas meu cashback não apareceu no extrato. E agora?',
      answer: 'Não se preocupe. Após realizar a compra de algum produto nas lojas parceiras, poderá levar até 3 horas para identificarmos seu cashback pendente e adicioná-lo ao seu extrato.'
    },
    {
      question: 'Em quanto tempo meu cashback estará disponível?',
      answer: 'O seu cashback estará disponivel para ser utilizado 90 dias após a loja parceira confirmar que o pagamento foi efetuado com sucesso. Este tempo traz garantia para a loja parceira e para você, formalizando o processo de pagamento.'
    },
    {
      question: 'Preciso fazer parte do clube de vantagens para ter direito ao cashback?',
      answer: 'Sim, é necessário que faça parte do clube para ter direito ao cashback e utilizá-lo no momento de sua compra.'
    },
    {
      question: 'Ao efetuar a compra por outros links ainda terei o cashback creditado em minha conta?',
      answer: 'Nao. Caso utilize links alternativos ou comparadores de preço, a sua compra será finalizada. Porém, o cashback não será aplicado em seu extrato.'
    },
    {
      question: 'Preciso me cadastrar na loja parceira para realizar a compra?',
      answer: 'Sim. É necessário estar logado em sua conta na loja parceira antes de clicar no link de compras.'
    },
    {
      question: 'Eu ganho cashback comprando nas lojas física dos parceiros?',
      answer: 'Não. O cashback é gerado apenas para compras realizadas no e-commerce das lojas parceiras.'
    },
  ]

  return (
    <div className="cashback_faq_page">

      <TopBanner item={topBannerItem} />

      <div className="section_header">
        É fácil economizar!
      </div>

      <div className="middle_section">
        {
          middleCards.map((content, i) =>

            <div className="card-panel z-depth-1 faq_middle_card" key={`middle-card-${i}`}>

              {content.icon}

              {content.text}

            </div>
          )
        }
      </div>

      <div className="section_header">
        Ainda tem dúvidas? Criamos um FAQ para você!
      </div>

      {quiz.map((item, index) => (
        <Collapsible
          key={`collapsible-${index}`}
          data-id={index}
          onSelect={() => {
            closeCollapsibles(index)
          }}>

          <CollapsibleItem
            header={
              <>
                {item.question}
                <i className="material-icons">expand_more</i>
              </>
            }
            node="div"
          >
            {item.answer}
          </CollapsibleItem>
        </Collapsible>
      ))}
    </div>
  );
};
