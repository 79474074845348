import { FC, forwardRef, Ref } from "react"
import { NavLink, useLocation, NavLinkProps } from "react-router-dom"

type Anchor_Ref = Ref<HTMLAnchorElement> | undefined

export const QueryLink: FC<NavLinkProps & { ref?: Anchor_Ref }> = forwardRef(({ children, to, ...props }, ref: Anchor_Ref) => {

  let { search } = useLocation()
  //console.log('[QueryLink] ref : ', ref)

  return (
    <NavLink to={to + search} {...props} ref={ref}>
      {children}
    </NavLink>
  )
})
