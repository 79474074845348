import { FC, } from "react"

import { Modal, } from "react-materialize";

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter
} from "@/features/propzMedia/propzMediaSlice"

import './style.sass'

import {
  closeSVG,
} from '@/assets/icons'

import {
  Icon,
} from '@/parts'

export const FaleConoscoModal: FC = () => {

  const dispatch = useAppDispatch()

  const { showFaleConoscoModal } = useAppSelector(
    (state) => state.propzMedia
  )

  const {
    glob_retailerPhone,
    glob_retailerEmail,
    glob_retailerWhatsapp,
    glob_website,
  } = window.parameters

  return (
    <Modal
      bottomSheet
      id="contactModal"
      open={showFaleConoscoModal}
      options={{
        dismissible: true,
        preventScrolling: true,
        onCloseEnd: () => dispatch(setPropzMediaStateParameter({ showFaleConoscoModal: false }))
      }}
    >
      <Icon image={closeSVG} color='#393939' className='contactModal_icon' onClick={() => {
        dispatch(setPropzMediaStateParameter({ showFaleConoscoModal: false }))
      }} />

      <span className="header">
        Fale conosco
      </span>

      <div className="line">
        <span>Telefone:</span>
        <a href={`tel:${glob_retailerPhone}`}>{glob_retailerPhone}</a>
      </div>

      <div className="line">
        <span>WhatsApp:</span>
        <a href={`${glob_retailerWhatsapp}`} target="_blank" rel="noopener noreferrer">
          {glob_retailerWhatsapp?.replace('https://wa.me/', '')}
        </a>
      </div>

      <div className="line">
        <span>Email:</span>
        <a href={`mailto:${glob_retailerEmail}`}>
          {glob_retailerEmail}
        </a>
      </div>

      <div className="line">
        <span>Site:</span>
        <a
          href={`${glob_website}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {glob_website?.split("/")[2] || ""}
        </a>
      </div>
    </Modal >
  );
};