import { useMemo } from "react";
import { useMobileQuery } from "@assets/hooks/useMobileQuery";

type Mobile = 0 | 1

//to calculate size relative to current size and mock size

export function useScale(current_size: number | undefined, mobile_mock_size: number, desktop_mock_size: number): { scale: number, mobile: Mobile } {

  const mobile = useMobileQuery()

  const scale = useMemo(() => {
    return current_size ? current_size / (mobile ? mobile_mock_size : desktop_mock_size) : 0
  }, [
    current_size,
    mobile_mock_size,
    desktop_mock_size,
    mobile,
  ])

  return { scale, mobile: Number(mobile) as Mobile }
}