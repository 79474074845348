import React from "react"
import ReactDOM from 'react-dom'
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { store } from "./app/store"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import App from "./App"
import {isApp} from "@assets/utils/isApp"

import elementClosest from 'element-closest'

elementClosest(window)

import { templateBasename } from '@assets/utils/templateBasename'
import { isApp } from '@assets/utils/isApp'

import "materialize-css/dist/css/materialize.css"
import "react-datepicker/dist/react-datepicker.min.css"

import M from 'materialize-css'
window.M = M

import "./index.sass"
import "&/sass/components/rounded_checkbox.sass"

import { ignoreErrors } from "@/utils/sentry/ignoreErrors"

import * as icons from '@/assets/icons'
//preload icons
Object.values(icons).map(value => {
  let i = new Image()
  i.crossOrigin = "Anonymous"
  i.src = value
})

Sentry.init({
  dsn: "https://4775a30f8478410d9a8f0ccf18a2abad@o379176.ingest.sentry.io/4504552802418688",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.02,
  enabled: import.meta.env.MODE !== 'development',
  environment: `PDC - ${window.location.host}`,
  //replaysOnErrorSampleRate: 1.0,

  ignoreErrors,
  denyUrls: [
    /leaflet\.propzdev3\.name/,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /go-mpulse\.net/,
    /script\.hotjar\.com/,
    /pdf\.min\.mjs/,
    /clarity\.ms/,
    /googletagmanager\.com/,
  ],
  beforeSend(event, hint) {

    //console.log('[sentry] beforeSend event, hint : ', event, hint)

    if (
      event.request?.url?.includes('leaflet.propzdev3.name')
      ||
      (
        hint?.originalException?.message?.includes("update")
        &&
        hint?.originalException?.message?.includes("depth")
      )
      ||
      (
        hint?.originalException?.stack?.includes("update")
        &&
        hint?.originalException?.stack?.includes("depth")
      )
      ||
      (
        event.exception?.values[0]?.value?.includes("update")
        &&
        event.exception?.values[0]?.value?.includes("depth")
      )
    ) {
      return null
    }

    return event
  }
});

/* if (window.screen.orientation && window.screen.orientation.lock) {
  document.body.requestFullscreen()
  .then(()=>window.screen.orientation.lock('portrait-primary'))
  
} */

const basename = templateBasename("portal-do-cliente")

const mktCampaign = localStorage.getItem('mktCampaign')

if (!mktCampaign) {
  if (isApp()) {
    localStorage.setItem('mktCampaign', 'pwa-start:')
  } else {
    localStorage.setItem('mktCampaign', 'organic:')
  }
}

if (window.notAuth) {

  (window as Window).location = `${window.location.origin}${basename.replace('portal-do-cliente', 'auth-do-cliente')}${window.location.search}`
}

Promise.all([
  new Promise(resolve => {
    // let the config from configPartner.html load first
    document.addEventListener('configLoaded', resolve, { once: true })
  }),
]).finally(() => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={basename}>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
})