import React, { FC, forwardRef } from "react"
import { NavLink, useLocation, NavLinkProps } from "react-router-dom"

import { useDebounceCallback } from 'usehooks-ts'

// link that doesn't loose current search query

export const QueryLink: FC<NavLinkProps> = forwardRef(({ children, to, onClick, ...props }, ref) => {

  let { search } = useLocation()

  const deb_onClick = useDebounceCallback((e) => {
    if (onClick) {
      onClick(e)
    }
  })

  return (
    <NavLink onClick={deb_onClick} to={to + search} {...props} ref={ref as React.Ref<HTMLAnchorElement> | undefined}>
      {children}
    </NavLink>
  )
})
