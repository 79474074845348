import { FC, useCallback, useEffect, useRef, useState, } from "react"

import { Modal } from "react-materialize"

import { pdcStore } from "@assets/store/pdcStore"
import { ReactComponent as CloseSVG } from '@assets/icons/close.svg'
import { ReactComponent as LeftSVG } from '@assets/icons/left_chevron.svg'
import { ReactComponent as RightSVG } from '@assets/icons/right_chevron.svg'

import { BellIcon } from "./BellIcon"
import { useComponentSize } from "@assets/hooks/useComponentSize"
import { useScale } from '@assets/hooks/useScale'

import { Content, DeniedNotificationsModal_css, } from './style'
import { sizes } from './sizes'

import {
  text_nowrap,
  semibold,
  cursor_pointer,
  hover_darken,
  cursor_default,
  color_primary,
  light,
} from '@assets/constants/styles/classes'

import { useMobileQuery } from "@assets/hooks/useMobileQuery"
import { cx } from "@linaria/core"
import {
  chrome,
  edge,
  firefox,
  opera,
  samsung,
} from "./browser_steps"

import {
  getNextRegStep,
} from "@/utils/internal functions"

import { orderedPopupTimings } from "@assets/constants/orderedPopupTimings"
import { useHistory, useLocation } from "react-router-dom"
import { localStore } from "@/store/localStore"

interface Props {
  name: string;
  steps: {
    icon: React.ReactElement;
    text: React.ReactElement;
  }[];
  className?: string;
}

const StepsDescription: FC<Props> = ({ name, steps, className }) => {
  // Steps description for provided browser
  return (
    <div className={`steps_description ${className ?? ''}`} >

      <div className={`steps_description_name ${semibold}`}>
        {name}
      </div>

      <div className="steps">
        {
          steps.map(step => (
            <div className="step">
              <div className="step_icon">
                {step.icon}
              </div>

              <span className={`step_text ${cx(text_nowrap, cursor_default)}`}>
                {step.text}
              </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

/* 
  Popup do describe steps needed to enable web push notifications in certain browsers
*/
export const DeniedNotificationsModal: FC = () => {

  const { search, pathname } = useLocation()
  const history = useHistory()

  const checkNotificationsPermission = pdcStore.use.checkNotificationsPermission()

  const [from_ordered_popup_flow, set_from_ordered_popup_flow] = useState(false)

  const UserWantsPWA = pdcStore.use.UserWantsPWA()

  const sec_brows = useRef<HTMLDivElement>(null)

  const [scrolled_right, set_scrolled_right] = useState(false)

  const mobile = useMobileQuery()

  const deniedNotificationsModalState = pdcStore.use.deniedNotificationsModalState()

  const [show_secondary_browsers, set_show_secondary_browsers] = useState(false)

  const closePopup = useCallback(
    () => {
      pdcStore.set.deniedNotificationsModalState({
        continue: true,
        opened: false
      })

      pdcStore.set.checkNotificationsPermission(null)

      set_from_ordered_popup_flow(from_ordered_popup_flow => {
        if (from_ordered_popup_flow) {

          setTimeout(() => {
            if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {

              pdcStore.set.lastClosedPopup('SplashscreenAppCTA')
            }
          }, orderedPopupTimings.SplashscreenAppCTA)
        }

        return false
      })
    }
    , [])

  const checkPermission = useCallback((permission: any, event?: string | null | boolean) => {
    switch (permission.state) {
      case 'granted':

        closePopup()

        if (event && event !== true) {
          window.dataLayer.push({
            event,
            pwaTrackingId: window.pwaTrackingId,
          })
        }

        pdcStore.set.triggerUpdateFirebaseRegistration()

        break
      default:
        console.log('[DeniedNotificationsModal] permission default')

        pdcStore.set.deniedNotificationsModalState({
          continue: false,
          opened: true
        })

        localStore.set.lastShowedDeniedNotificationsModal(new Date().getTime())

        pdcStore.set.increment_showed_popups_count()

        window?.Notification?.requestPermission()
    }
  }, [])

  useEffect(() => {
    if (checkNotificationsPermission) {

      if (checkNotificationsPermission == 'push_notification_ordered_popup_flow') {
        set_from_ordered_popup_flow(true)
      }

      try {
        window.navigator?.permissions?.query({ name: 'notifications' })
          .then(permission => {

            checkPermission(permission, checkNotificationsPermission)

            permission.onchange = () => {
              //console.log('notification permission changed : ', permission)
              checkPermission(permission, checkNotificationsPermission)
            }
          })
      } catch (e) {
        console.log('[DeniedNotificationsModal] notification permission error : ', e)
        pdcStore.set.deniedNotificationsModalState({
          continue: true,
          opened: false
        })
      }
    }
  }, [checkNotificationsPermission])

  const [content_ref, size] = useComponentSize(deniedNotificationsModalState.opened)

  const scale = useScale(size?.width, 366, 1473)

  //next step
  useEffect(() => {

    if (deniedNotificationsModalState.continue) {

      pdcStore.set.deniedNotificationsModalState({
        opened: false,
        continue: false
      })

      if (pathname.includes("cadastro-aceite-webpush")) {
        history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)

      } else if (pathname.includes('webpush-pagina')) {
        history.push(`/${search}`)

      } else if (UserWantsPWA && window.PWAInstallEvent?.prompt) {

        window.PWAInstallEvent.prompt()

        window.PWAInstallEvent.userChoice
          .then(({ outcome }: { outcome: string }) => {
            pdcStore.set.pwa_event(null)
            console.log(`User response to the install prompt: ${outcome}`)

            window.dataLayer.push({
              event: outcome == "accepted" ? "pwa - access_granted" : "pwa - access_rejected",
              pwaTrackingId: window.pwaTrackingId
            })
          })
      }
    }
  }, [deniedNotificationsModalState])

  return (
    <Modal
      open={deniedNotificationsModalState.opened}

      id="DeniedNotificationsModal"
      //@ts-ignore
      className={DeniedNotificationsModal_css}
      options={{
        dismissible: true,
        startingTop: "10vh",
        onCloseEnd: () => {
          closePopup()
        },
      }}
    >
      <Content ref={content_ref} {...scale} {...{ sizes }}>

        <CloseSVG className={`close_button ${cursor_pointer}`} onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          closePopup()
        }} />

        {
          mobile && show_secondary_browsers ?
            <></>
            :
            <BellIcon className="main_icon" />
        }

        <div className={`header ${mobile && show_secondary_browsers ? 'headless' : ''} ${cursor_default}`}>
          {
            from_ordered_popup_flow ?
              'Você está perdendo suas ofertas!'
              :
              'Habilite as notificações do seu navegador'
          }
        </div>

        <div className={`text ${cx(cursor_default, text_nowrap)}`}>
          {
            from_ordered_popup_flow ?
              <>
                {'Tentamos te avisar sobre novas ofertas, mas seu navegador '}
                {
                  mobile ? <br /> : <></>
                }
                está bloqueando as notificações. Veja como é fácil ativá-las:
              </>
              :
              <>
                {'Aproveite as melhores ofertas ativando as notificações em '}
                {
                  mobile ? <br /> : <></>
                }
                seu navegador. Basta seguir os passos abaixo:
              </>
          }

        </div>

        {
          show_secondary_browsers ?
            <>
              <div className="carousel_container">
                {
                  mobile && show_secondary_browsers ?
                    <></>
                    :
                    <a className={`left_arrow ${scrolled_right ? cx(cursor_pointer, hover_darken) : ''}`} onClick={() => {
                      sec_brows.current?.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                      })
                      set_scrolled_right(false)
                    }}>
                      {
                        scrolled_right ? <LeftSVG /> : <></>
                      }
                    </a>
                }

                <div className="secondary_browsers roll_in_from_right_animation" ref={sec_brows}>

                  <StepsDescription {...firefox} className="big" />

                  <StepsDescription {...edge} />

                  <StepsDescription {...opera} className="big" />
                </div>

                {
                  mobile && show_secondary_browsers ?
                    <></>
                    :
                    <a className={`right_arrow ${scrolled_right ? '' : cx(cursor_pointer, hover_darken)}`} onClick={() => {
                      sec_brows.current?.scroll({
                        top: 0,
                        left: sec_brows.current?.scrollWidth,
                        behavior: 'smooth',
                      })
                      set_scrolled_right(true)
                    }}>
                      {
                        scrolled_right ? <></> : <RightSVG />
                      }
                    </a>
                }

              </div>

              <a className={`popup_back_button roll_in_from_right_animation ${cx(cursor_pointer, color_primary, semibold)}`} onClick={() => {
                set_show_secondary_browsers(false)
              }}>
                Voltar
              </a>
            </>
            :
            <>
              <div className="main_browsers roll_in_from_right_animation">
                <StepsDescription {...chrome} />

                <StepsDescription {...samsung} className="big" />
              </div>

              <div className={`bottom_text roll_in_from_right_animation ${cx(cursor_default, light)}`}>
                {'Utiliza outro navegador? '}
                <a onClick={() => {
                  set_show_secondary_browsers(true)
                }} className={cx(cursor_pointer, color_primary, semibold)}>Clique aqui</a>
                {' para saber mais.'}
              </div>
            </>
        }
      </Content>
    </Modal>
  )
}